import React from 'react';

import { useSelector, useDispatch } from 'react-redux'

import { KrakenInstance } from './KrakenInstance'

function App() {

	const instances = useSelector(state => state.instances)
	const dispatch = useDispatch()

	return (
		<div>
			<header>
				<p>
					{ instances.length }
				</p>
				<button id="btn-create-instance" onClick={ () => dispatch({ type: 'create_instance' }) }>
					create instance
				</button>
				<button id="btn-create-canvas-instance" onClick={ () => dispatch({ type: 'create_canvas_instance' }) }>
					create canvas instance
				</button>

				{
					instances.map((instance) => 
						<KrakenInstance key={ instance.name } name={ instance.name } type={ instance.type }/>
					)
				}

			</header>
		</div>
	);
}

export default App;
