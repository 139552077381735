import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Kraken } from './krakenclient.js'

import { KrakenParticipant } from './KrakenParticipant'

// const endpoint = "wss://kraken.synthesis.rocks/kraken"
const endpoints = [
	{ name: "mediakraken_a", url: "wss://mediakraken.synthesis.rocks/kraken" },
	// { name: "a", url: "ws://192.168.1.27:9090/kraken" },
	// { name: "b", url: "ws://192.168.1.27:9091/kraken" },
	// { name: "c", url: "ws://192.168.1.27:9092/kraken" },
	// { name: "d", url: "ws://192.168.1.27:9093/kraken" },
]

const get_random_endpoint = () => {
	return endpoints[Math.floor(Math.random() * endpoints.length)]
}

export function KrakenInstance(props) {
	
	const [theEndpoint, setEndpoint] = useState(null)
	const [theKraken, setKraken] = useState(null)
	const [thePlayers, setPlayers] = useState([])
	const [sendPositionUpdates, setSendPositionUpdates] = useState(false)
	const [positionUpdateInterval, setPositionUpdateInterval] = useState(null)

	const [videoEnabledByPlayer, setVideoEnabledByPlayer] = useState({})
	const [videoConsumerIdByPlayer, setVideoConsumerIdByPlayer] = useState({})

	const preview_element_name = `${props.name}_preview`
	const canvas_element_name = `${props.name}_canvas`

	const instance_name = props.name
	const instance_type = props.type

	useEffect(() => {

		function setup_kraken() {
			const kraken = new Kraken(instance_name)

			kraken.on(kraken.events().connected, async () => {
				console.log(`Kraken ${instance_name} connected!`)

				if (instance_type === "canvas") {
					var c = document.getElementById(canvas_element_name)

					var centreX = 45; var centreY = 45;
					var radius = 90;
					var rotateAngle = 36 * Math.PI / 180;
					var startAngle = 0 * Math.PI / 180;
					var endAngle = 36 * Math.PI / 180;
					var counter = 0;
					var palettes = [
						["#99e1e5", "#f3e8cb", "#f2c6b4", "#fbafaf", "black", "#0c056d", "#590d82", "#b61aae", "#f25d9c", "white"],
						["#155263", "#ff6f3c", "#ff9a3c", "#ffc93c", "black", "#283149", "#404b69", "#f73859", "#dbedf3", "white"],
						["#393232", "#4d4545", "#8d6262", "#ed8d8d", "black", "#071a52", "#086972", "#17b978", "#a7ff83", "white"],
						["#3a0088", "#930077", "#e61c5d", "#ffbd39", "black", "#233142", "#455d7a", "#f95959", "#e3e3e3", "white"],
					]
					var colours = palettes[random_between(0, 3)]
		 
					var ctx = c.getContext("2d");

					setInterval(() => {
						ctx.translate(centreX, centreY);
						ctx.rotate(rotateAngle);
						ctx.translate(-centreX, -centreY);

						for (var i = 0; i < 10; i++) {  
							ctx.fillStyle = colours[i];  
							ctx.translate(centreX, centreY);  
							ctx.rotate(rotateAngle);  
							ctx.translate(-centreX, -centreY);  
							ctx.beginPath();  
							ctx.moveTo(centreX, centreY);  
							ctx.lineTo(centreX + radius, centreY);  
							ctx.arc(centreX, centreY, radius, startAngle, endAngle, false);  
							ctx.closePath();  
							ctx.fill();  
						}

						counter++;  
						if (counter > colours.length) {  
							counter = 0;  
						}
					}, 100)
		
					await kraken.start_producing_stream(c.captureStream(20), document.getElementById(preview_element_name))
				}
				else {
					// Grab media devices we want initially
					//
					const video_devices = await kraken.get_video_devices()
					const audio_devices = await kraken.get_audio_devices()
			
					// Just pick the first one of each (this should be user selectable in the future)
					//
					const picked_video_device = video_devices[0]
					const picked_audio_device = audio_devices[0]
					console.log(`Picked video device ${picked_video_device.label} (id ${picked_video_device.deviceId})`)
					console.log(`Picked audio device ${picked_audio_device.label} (id ${picked_audio_device.deviceId})`)
			
					// Start producing 
					//
					await kraken.start_producing_selfie(picked_video_device, picked_audio_device, document.getElementById(preview_element_name))
				}
			})
		
			kraken.on(kraken.events().disconnected, () => {
				console.log("Kraken disconnected!")
			})
		
			kraken.on(kraken.events().player_entered, async (client_id) => {
				console.log(`${instance_name} adding ${client_id} to ${JSON.stringify(thePlayers)}`)
				setPlayers((state) => [
					...state, 
					{
						id: client_id,
						tracks: [],
					}
				])
			})
			
			kraken.on(kraken.events().player_exited, async (client_id) => {
				console.log(`${instance_name} removing ${client_id} from ${JSON.stringify(thePlayers)}`)
				setPlayers((state) => 
					state.filter((p) => p.id !== client_id)
				)
			})

			kraken.on(kraken.events().player_added_track, async (client_id, producer_id, kind) => {
				console.log(`${instance_name} noticed that ${client_id} added a new ${kind} track ${producer_id}`)
				setPlayers((state) => {
					const state_copy = JSON.parse(JSON.stringify(state))
					const the_player = state_copy.find((p) => p.id == client_id)
					the_player.tracks.push({
						producer_id, kind
					})
					
					return state_copy
				})
			})

			kraken.on(kraken.events().player_position_updated, async (client_id, x, y, velocity_x, velocity_y) => {
				//console.log(`${instance_name} updating position of ${client_id} -> x: ${x} y: ${y} v_x: ${velocity_x} v_y: ${velocity_y}`)
			})
		
			const endpoint = get_random_endpoint()
			setEndpoint((state) => endpoint)

			kraken.connect(endpoint.url)
			setKraken((state) => kraken)
		}

		if (!theKraken) {
			setup_kraken()

		}
	}, [])

	// Starting x: 2450 y: 1200
	const update_delay = 100
	const min_x = 2000
	const max_x = 2900
	const min_y = 900
	const max_y = 1500
	var currentX = 2000

	const random_between = (min, max) => {
		return min + Math.floor((max - min) * Math.random())
	}

	const toggle_position_updates = () => {
		const position_updates = !sendPositionUpdates
		setSendPositionUpdates(() => position_updates)
		
		console.log(`Position update: ${position_updates}`)
		var currentY = random_between(min_y, max_y)

		if (position_updates) {
			const interval = setInterval(
				() => {
					currentX = currentX >= max_x ? min_x : currentX + 5
					theKraken.emit_event("PLAYER_VELOCITY_INPUT", { x: currentX, y: currentY, velocityX: 0, velocityY: 0 })
				}, 
				update_delay
			)
			setPositionUpdateInterval(() => interval)
		}
		else {
			clearInterval(positionUpdateInterval)
			setPositionUpdateInterval(() => null)
		}
	}

	const get_track_of_kind = (player, kind) => {
		return player.tracks.find((t) => t.kind === kind)
	}

	const toggle_consume = (player, track) => async () => {
		console.log(`${player.id} toggling consume of ${JSON.stringify(track)}`)
		console.log(`Video enabled: ${videoEnabledByPlayer[player.id]}`)
		console.log(player)
		const media_element = document.getElementById(`${instance_name}_${player.id}`)
		
		if (track) {
			if (!videoEnabledByPlayer[player.id]) {
				const consumer_id = await theKraken.start_consuming(player.id, track.producer_id, media_element)
				console.log(`Got consumer: ${consumer_id}`)
				setVideoEnabledByPlayer((state) => { 
					const new_state = { ...state }
					new_state[player.id] = true
					return new_state
				})
				setVideoConsumerIdByPlayer((state) => {
					const new_state = { ...state }
					new_state[player.id] = consumer_id
					return new_state
				})
			}
			else {
				await theKraken.stop_consuming(videoConsumerIdByPlayer[player.id], media_element)
				setVideoEnabledByPlayer((state) => {
					const new_state = { ...state }
					new_state[player.id] = false
					return new_state
				})
			}
		}
	}

	const toggle_consume_all = () => {
		console.log(`Toggle all!`)
		console.log(thePlayers)
		thePlayers.map((player) => {
			toggle_consume(player, get_track_of_kind(player, 'video'))()
		})
	}

	const streamElement = instance_type === "canvas"
		? <canvas id={ canvas_element_name } width="90px" height="90px"></canvas>
		: <video id={ preview_element_name } autoPlay height="50pt" muted></video>

	return (
		<div>
			{/* ({ theEndpoint && theEndpoint.name } { props.name }) */}
			[
				{streamElement}
			] =&gt;
			&nbsp; 
			<input 
				name="do_position_updates"
				type="checkbox"
				checked={ sendPositionUpdates }
				onChange={ toggle_position_updates }
				/>
			&nbsp; 
			<button
				onClick={ toggle_consume_all }
				>Toggle All</button>
			{
				theKraken && thePlayers.map((player) => 
					<video 
						key={ `${instance_name}_${player.id}` }
						id={ `${instance_name}_${player.id}` } 
						className="video_preview"
						autoPlay 
						height="50pt" 
						width="50pt" 
						style={{ backgroundColor: "#000" }}
						onClick={ toggle_consume(player, get_track_of_kind(player, 'video')) }
					></video>
				)
			}
			{/* {
				JSON.stringify(theParticipants)
			} */}
		</div>
	)
}
