import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import "./index.css";
import App from "./App";
import { KrakenInstance } from "./krakenclient";

const default_state = {
  name_suffix: 0,
  instances: [],
};

const rootReducer = (state = default_state, action) => {
  switch (action.type) {
    case "create_instance": {
      const instance_name = `kraken_${state.name_suffix}`;

      return {
        ...state,
        instances: [...state.instances, { name: instance_name, type: "video" }],
        name_suffix: state.name_suffix + 1,
      };
      break;
		}
    case "create_canvas_instance": {
      const instance_name = `kraken_${state.name_suffix}`;

      return {
        ...state,
        instances: [...state.instances, { name: instance_name, type: "canvas" }],
        name_suffix: state.name_suffix + 1,
      };
      break;
    }		
    default:
      return state;
  }
};

const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
