//
// Webpack should automagically include the EventEmitter module... ?
//
import { EventEmitter } from "events"
import { Device } from "mediasoup-client"

const random_id = () => {
	return Math.random().toString(36).slice(-8)
}

const Kraken = class extends EventEmitter {
	constructor(profile_name) {
		super()
		this.profile_name = profile_name

		// Handling of inflight requests (that could have replies)
		//
		this.inflight_requests = {}

		// Notifications start out disabled (obviously)
		//
		this.notifications_enabled = false

		// All our open consumers
		//
		this.consumers = {}

		// The current player state
		//
		this.players = {}
	}

	// Just a handy map of all potential event names
	//
	events() {
		return {
			connected: "connected",
			disconnected: "disconnected",
			player_entered: "player_entered",
			player_exited: "player_exited",
			player_added_track: "player_added_track",
			player_removed_track: "player_removed_track",
			player_position_updated: "player_position_updated",
			pong: "pong",
		}
	}

	enable_notifications() {
		Notification.requestPermission().then(() => {
			this.notifications_enabled = true
		})
	}

	request_all_participants() {
		this.__fire_and_forget("RTC", "request_participants")
	}

	async message_handler(message) {
		// Check if this is a reply
		//
		if (message.parent_id && this.inflight_requests[message.parent_id]) {
			const { resolve, reject } = this.inflight_requests[message.parent_id]
			delete this.inflight_requests[message.parent_id]
			resolve(message)
		} else {
			// Should only really be messages which come unsolicited (not linked to a particular request)
			// e.g. {"p_class":["PLAYER"],"type":"LOCAL_RTC_PHASE_0_SETUP","data":{},"event_id":"4oCfziERrl4M8Y0c"}
			//
			switch (message.type) {
				// This is sort of a "reset" type event - i.e. lets start the setup media phase from the beginning
				//
				case "LOCAL_RTC_PHASE_0_SETUP": {
					// TBD - clean up any connected stuff we might have
					if (this.device && this.device.loaded) {
						this.device = null
					}

					// The mediasoup client device
					//
					this.device = new Device()

					// Fetch the capabilities of the server / router
					//
					const phase_2 = await this.__fire_and_await_reply("LOCAL_RTC_PHASE_1_SETUP", { profile_name: this.profile_name })

					// Pass server capabilities to the local device
					//
					await this.device.load({ routerRtpCapabilities: phase_2.data.rtpCapabilities })

					// Check local capabilities
					//
					if (!this.device.canProduce("video")) {
						console.log("Device unable to produce video!")
					} else if (!this.device.canProduce("audio")) {
						console.log("Device unable to produce audio!")
					} else {
						// Request to create the server side send transports
						//
						const phase_4 = await this.__fire_and_await_reply("LOCAL_RTC_PHASE_3_SETUP", { sctpCapabilities: this.device.sctpCapabilities })

						// Create the local send transport
						//
						this.send_transport = this.device.createSendTransport(phase_4.data.send_transport)

						// Connect send transport
						//
						this.send_transport.on("connect", async ({ dtlsParameters }, callback, err_callback) => {
							console.log(`Send transport connecting...`)
							const connect_reply = await this.__fire_and_await_reply("LOCAL_RTC_PHASE_REQUIRE_TRANSPORT_CONNECT", {
								direction: "send",
								dtlsParameters: dtlsParameters,
							})
							console.log(`Send transport connected!`)
							callback()
						})

						// Only for the send transport
						//
						this.send_transport.on("produce", async ({ kind, rtpParameters, appData }, callback, err_callback) => {
							console.log(`Send transport now producing ${kind}!`)
							const create_producer_reply = await this.__fire_and_await_reply("LOCAL_RTC_REQUIRE_PRODUCER", {
								kind,
								rtpParameters,
							})

							// Ack the producer id
							//
							console.log(`Server-side producer id: ${create_producer_reply.data.producer_id}`)
							callback(create_producer_reply.data.producer_id)
						})

						// Create the local recv transport
						//
						this.recv_transport = this.device.createRecvTransport(phase_4.data.recv_transport)

						// Connect recv transport
						//
						this.recv_transport.on("connect", async ({ dtlsParameters }, callback, err_callback) => {
							console.log(`Recv transport connecting...`)
							const connect_reply = await this.__fire_and_await_reply("LOCAL_RTC_PHASE_REQUIRE_TRANSPORT_CONNECT", {
								direction: "recv",
								dtlsParameters: dtlsParameters,
							})
							console.log(`Recv transport connected!`)
							callback()
						})

						// Now we are connected, transports all up
						//
						this.emit(this.events().connected, phase_4.data.client_id)
					}

					break
				}
				case "PLAYER_ENTERED": {
					const player_instance_id = message.player_instance_id
					this.emit(this.events().player_entered, player_instance_id)
					break
				}
				case "PLAYER_EXITED": {
					const player_instance_id = message.player_instance_id
					this.emit(this.events().player_exited, player_instance_id)
					break
				}
				case "PLAYER_ADDED_TRACK": {
					const player_instance_id = message.player_instance_id
					const producer_id = message.data.producer_id
					const kind = message.data.kind
					this.emit(this.events().player_added_track, player_instance_id, producer_id, kind)
					break
				}
				case "PONG": {
					const player_instance_id = message.player_instance_id
					const producer_id = message.data.producer_id
					this.emit(this.events().pong, player_instance_id, producer_id, message.data)
					break
				}
				// case "GAME_STATE_UPDATED": {
				// 	for (const [player_instance_id, event] of Object.entries(message.data)) {
				// 		const { x, y, velocityX, velocityY } = event.data
				// 		this.emit(this.events().player_position_updated, player_instance_id, event.producer_id, x, y, velocityX, velocityY)
				// 	}
				// 	break
				// }
				case "WORLD_STATE_UPDATED": {
					const client_tick_timestamp = new Date().getTime()
					const server_tick_timestamp = message.event_timestamp

					const new_player_state = message.data.players
					this.merge_and_emit_player_state({ new_player_state, latency: client_tick_timestamp - server_tick_timestamp })
					break
				}
				default: {
					console.log(`Unhandled message:`)
					console.log(message)
					break
				}
			}
		}
	}

	async get_devices_of_kind(kind) {
		const all_devices = await navigator.mediaDevices.enumerateDevices()
		return all_devices
			.filter((device) => device.kind == kind)
			.map((device) => {
				return {
					deviceId: device.deviceId,
					groupId: device.groupId,
					kind: device.kind,
					label: device.label,
				}
			})
	}

	async get_video_devices() {
		return await this.get_devices_of_kind("videoinput")
	}

	async get_audio_devices() {
		return await this.get_devices_of_kind("audioinput")
	}

	async start_producing_selfie(video_device, audio_device, preview_element = null) {
		const user_media_constraints = {
			video: {
				width: { exact: 120 },
				height: { exact: 120 },

				// Be specific about device
				//
				groupId: video_device.groupId,
				deviceId: video_device.deviceId,
			},
			audio: {
				sampleRate: { exact: 48000 },
				sampleSize: { exact: 16 },
				channelCount: { exact: 1 },
				echoCancellation: true,
				noiseSuppression: true,
				autoGainControl: true,

				// Be specific about device
				//
				groupId: audio_device.groupId,
				deviceId: audio_device.deviceId,
			},
		}

		navigator.mediaDevices.getUserMedia(user_media_constraints).then((stream) => {
			// Always select the first track?
			//
			const selfie_video_track = stream.getVideoTracks()[0]
			const selfie_audio_track = stream.getAudioTracks()[0]

			// Attach for preview
			//
			if (preview_element) {
				preview_element.srcObject = stream
			}

			// Construct the producers
			//
			const selfie_video_producer = this.send_transport.produce({ track: selfie_video_track })
			const selfie_audio_producer = this.send_transport.produce({ track: selfie_audio_track })
		})
	}

	async start_producing_stream(stream, preview_element) {
		// Always select the first track?
		//
		const selfie_video_track = stream.getVideoTracks()[0]
		const selfie_audio_track = stream.getAudioTracks()[0]

		// Attach for preview
		//
		if (preview_element) {
			preview_element.srcObject = stream
		}

		// Construct the producers
		//
		if (selfie_video_track) {
			console.log("Producing canvas video")
			this.send_transport.produce({ track: selfie_video_track })
		}
		if (selfie_audio_track) {
			console.log("Producing audio")
			this.send_transport.produce({ track: selfie_audio_track })
		}
	}

	async start_consuming(client_id, producer_id, video_element) {
		// Create server side consumer
		//
		const consume_reply = await this.__fire_and_await_reply("LOCAL_RTC_REQUIRE_CONSUMER", {
			client_id: client_id,
			producer_id: producer_id,
			rtpCapabilities: this.device.rtpCapabilities,
		})

		const consumer = await this.recv_transport.consume({
			id: consume_reply.data.consumerId,
			producerId: consume_reply.data.producerId,
			kind: consume_reply.data.kind,
			rtpParameters: consume_reply.data.rtpParameters,
		})

		// Save it
		//
		this.consumers[consumer.id] = consumer

		// Add the new consumer track to the video element
		//
		if (video_element) {
			if (!video_element.srcObject) {
				video_element.srcObject = new MediaStream()
			}
			video_element.srcObject.addTrack(consumer.track)
		}

		// Request to resume playback server side, then client side
		//
		await this.__fire_and_await_reply("LOCAL_RTC_REQUIRE_CONSUMER_RESUME", {
			consumer_id: consumer.id,
		})

		consumer.resume()

		return consumer.id
	}

	async stop_consuming(consumer_id, video_element) {
		const consumer = this.consumers[consumer_id]

		if (consumer) {
			// How do we remove the track?
			//
			if (video_element) {
				video_element.srcObject.removeTrack(consumer.track)
			}

			// Close the backend consumer
			//
			await this.__fire_and_await_reply("LOCAL_RTC_REQUIRE_CONSUMER_CLOSE", {
				consumer_id: consumer.id,
			})

			// Close the client side
			//
			consumer.close()

			// Remove from our list of tracked consumers
			//
			delete this.consumers[consumer_id]
		}
	}

	async emit_event(type, data = {}) {
		this.__fire_and_forget(type, data)
	}

	// connect() means connect to the signaling websocket, as well as bring up the two transports (send / recv)
	//
	connect(wss_url) {
		this.wss_url = wss_url
		this.want_connect = true

		const socket_connect = () => {
			if (this.want_connect) {
				this.socket = new WebSocket(wss_url)

				this.socket.onmessage = (event) => {
					const message = JSON.parse(event.data)
					this.message_handler(message)
				}

				this.socket.onclose = (event) => {
					console.info("Websocket disconnected, reconnecting...")
					this.socket = null
					setTimeout(() => socket_connect(), 5000)

					this.emit(this.events().disconnected)
				}

				this.socket.onopen = async (event) => {
					console.info("Websocket opened")
				}

				this.socket.onerror = (event) => {
					console.info("Websocket error")
					console.error(event)
					this.socket.close()
					this.socket = null
				}
			} else {
				console.log("No longer connecting")
			}
		}

		// Connect the websocket immediately
		//
		socket_connect()
	}

	disconnect() {
		if (this.socket) {
			this.want_connect = false
			this.removeAllListeners()
			this.socket.close()
		}
	}

	__fire_and_forget(type, data = {}) {
		if (this.socket && this.socket.readyState) {
			const event_id = random_id()
			const event_timestamp = new Date().getTime()
			const envelope = { type, data, event_id, event_timestamp }
			this.socket.send(JSON.stringify(envelope))
		}
	}

	__fire_and_await_reply(type, data = {}) {
		if (this.socket && this.socket.readyState) {
			const event_id = random_id()
			const event_timestamp = new Date().getTime()
			const envelope = { type, data, event_id, event_timestamp }

			// Create the promise and keep it for resolving or rejecting later (on reply)
			//
			const reply_promise = new Promise((resolve, reject) => {
				this.inflight_requests[event_id] = { resolve, reject }

				// Fire the request
				//
				this.socket.send(JSON.stringify(envelope))
			})

			return reply_promise
		} else {
			return Promise.reject("Socket not ready")
		}
	}

	_set_difference(set_a, set_b) {
		let _difference = new Set(set_a)
		for (let elem of set_b) {
			_difference.delete(elem)
		}
		return _difference
	}

	_set_intersection(set_a, set_b) {
		let _intersection = new Set()
		for (let elem of set_b) {
			if (set_a.has(elem)) {
				_intersection.add(elem)
			}
		}
		return _intersection
	}

	merge_and_emit_player_state (data) {
		var { new_player_state, latency } = data
		// console.log(`Current state:`)
		// console.log(this.players)
		// console.log(`New state:`)
		// console.log(new_player_state)

		// Check if we have added or removed players
		//
		const current_players = new Set(Object.keys(this.players))
		const new_players = new Set(Object.keys(new_player_state))

		// console.log(`Current players : ${[...current_players]}`)
		// console.log(`New players     : ${[...new_players]}`)

		const players_added = this._set_difference(new_players, current_players)
		const players_removed = this._set_difference(current_players, new_players)
		const players_intersected = this._set_intersection(current_players, new_players)

		// console.log(`Players added   : ${[...players_added]}`)
		// console.log(`Players removed : ${[...players_removed]}`)

		// Players to remove
		//
		for (const player of [...players_removed]) {

			// Maybe should remove tracks too?
			//
			for (const track of Object.values(this.players[player].tracks)) {
				this.emit(this.events().player_removed_track, player, track.producer_id, track.kind)
			}

			// Then emit removal
			//
			this.emit(this.events().player_exited, player)
			delete this.players[player]
		}

		// Brand new players
		//
		for (const player of [...players_added]) {
			this.players[player] = new_player_state[player]
			
			// Emit the new player, but also everything else (first time we are seeing him)
			//
			// console.log(`Emitting player_entered`)
			this.emit(this.events().player_entered, player)

			// Tracks
			//
			for (const track of Object.values(this.players[player].tracks)) {
				// console.log(`Emitting player_added_track`)
				this.emit(this.events().player_added_track, this.players[player].client_id, track.producer_id, track.kind)
			}

			// Position
			//
			this.emit(
				this.events().player_position_updated, 
				this.players[player].client_id, 
				this.players[player].position.x, 
				this.players[player].position.y, 
				this.players[player].position.velocity_x, 
				this.players[player].position.velocity_y,
				latency
			)
		}

		// Update existing players
		//
		for (const player of [...players_intersected]) {
			// Now check if we have added or removed tracks for players that were already around
			//
			const current_tracks = new Set(Object.keys(this.players[player].tracks))
			const new_tracks = new Set(Object.keys(new_player_state[player].tracks))
			const tracks_added = this._set_difference(new_tracks, current_tracks)
			const tracks_removed = this._set_difference(current_tracks, new_tracks)
			
			// console.log(`For player: ${player}`)
			// console.log(` Tracks added   : ${[...tracks_added]}`)
			// console.log(` Tracks removed : ${[...tracks_removed]}`)

			// Emit removed tracks
			//
			for (const track of [...tracks_removed]) {
				this.emit(
					this.events().player_removed_track, 
					player, 
					this.players[player].tracks[track].producer_id, 
					this.players[player].tracks[track].kind
				)
			}

			// Emit added tracks
			//
			for (const track of [...tracks_added]) {
				this.emit(
					this.events().player_added_track, 
					player, 
					new_player_state[player].tracks[track].producer_id, 
					new_player_state[player].tracks[track].kind
				)
			}

			// Update positions
			//
			this.emit(
				this.events().player_position_updated, 
				player, 
				new_player_state[player].position.x, 
				new_player_state[player].position.y, 
				new_player_state[player].position.velocity_x, 
				new_player_state[player].position.velocity_y,
				latency
			)
		}

		// And overwrite the state
		//
		this.players = new_player_state
	}
}

export { Kraken }
